import * as React from 'react';
import './ErrorBoundary.scss';
import { TFunction } from 'react-i18next';
import * as Sentry from '@sentry/react';
import Typography from '../../components/Typography';

export interface IErrorBoundaryProps {
  children: React.ReactNode;
  t: TFunction<'translation', undefined>;
}

export interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log('An error has occurred: ', error, errorInfo);
    Sentry.captureException(error);
  }

  public render() {
    const { hasError } = this.state;
    const { t, children } = this.props;

    if (hasError) {
      return (
        <div className="error-boundary-container">
          <div className="info">
            <Typography size={18} weight="700">
              {t('oops')}
            </Typography>
            <Typography size={16} weight="700" color="solarized">
              {t('somethingWentWrong')}
            </Typography>
            <Typography size={16} weight="700" color="secondary">
              {t('pleaseRefresh')}
            </Typography>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
