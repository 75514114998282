import { ReactComponent as UserCreditsCardIcon } from '../../../assets/UserCreditsCardBg.svg';
import { ReactComponent as UserCreditsIcon } from '../../../assets/UserCredits.svg';
import { ReactComponent as UserCoachingSessionsIcon } from '../../../assets/UserCoachingSessions.svg';

export default function UserCreditsCard() {
  return (
    <section
      className="grid grid-cols-12 w-full rounded-lg"
      style={{ background: 'linear-gradient(90deg, #F0F5FC 0%, #FFE9ED 100%)' }}
    >
      <div className="col-span-8 p-5 flex flex-col gap-8">
        <span className="text-rose-900 text-displayXs font-semibold">
          Discover the Mental Health Benefits provided by Cameron’s employer
        </span>
        <div className="flex gap-8">
          <div className="flex gap-2 items-center">
            <div className="bg-rose-100 rounded-2xl p-2">
              <UserCreditsIcon />
            </div>
            <div className="flex flex-col">
              <span className="text-xxs text-rose-900">Credits left</span>
              <span className="text-rose-950 font-semibold">Unlimited</span>
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <div className="bg-rose-100 rounded-2xl p-2">
              <UserCoachingSessionsIcon />
            </div>
            <div className="flex flex-col">
              <span className="text-xxs text-rose-900">Coaching sessions</span>
              <span className="text-rose-950 font-semibold">Unlimited</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-4 flex justify-end">
        <UserCreditsCardIcon />
      </div>
    </section>
  );
}
