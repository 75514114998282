import profileCover from '../../assets/UserInfoCardBg.svg';
import { extractInitials } from '../../utilities/common/Avatar';

export default function Avatar({ clientName }: { clientName: string }) {
  return (
    <section className="relative">
      <img
        src={profileCover}
        alt="profileCover"
        className="rounded-t-lg w-full"
      />
      <section className="w-12 h-12 rounded-full absolute top-6 left-4 flex items-center justify-center md font-semibold text-white bg-rose-800">
        {extractInitials(clientName)}
      </section>
    </section>
  );
}
