import { useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import ClientOverviewCardLoader from './ClientOverviewCardLoader';
import UserCreditsCard from './UserCreditsCard';
import UserEmergencyContactCard from './UserEmergencyContactCard';
import UserGoalsCard from './UserGoalsCard';
import UserInfoCard from './UserInfoCard';
import UserNotesCard from './UserNotesCard';
import UserPresentingIssuesCard from './UserPresentingIssuesCard';
import UserRiskLevelCard from './UserRiskLevelCard';
import UserTagsCard from './UserTagsCard';
import { TabPanel, Tabs } from '../../components/Tabs';
import { useClientDetailsV2 } from './hooks/UseClientDetails';
import decodeCode from '../../../utilities/common/Encryption';
import { SessionsList } from './Sessions/SessionsList';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import ClientDetailsContext from './context/ClientDetailsContext';

const TabsList = [
  { key: 1, label: 'Sessions' },
  { key: 2, label: 'Assignments' },
  { key: 3, label: 'Personal insight quiz' },
  { key: 4, label: 'Helpline logs' },
];

export default function ClientDetailsV2() {
  const { clientId, serviceType } = useParams();
  const decodedClientId = decodeCode(clientId as string).toString();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const { data: clientDetailData, isLoading: isClientDetailLoading } =
    useClientDetailsV2(decodedClientId, serviceType);

  const isLoading = isClientDetailLoading;

  const clientDetailsContextValues = useMemo(
    () => ({
      clientDetailData,
      isLoading,
      decodedClientId,
      encodedClientId: clientId,
      serviceType,
    }),
    [clientDetailData, isLoading, decodedClientId, serviceType],
  );

  return (
    <ClientDetailsContext.Provider value={clientDetailsContextValues}>
      <section className=" p-6 pt-[29px] box-border grid grid-cols-12 gap-6">
        <section className="col-span-12 flex justify-end">
          {clientDetailData ? (
            <Breadcrumbs
              className="w-full mb-2"
              list={[
                { title: 'Clients', link: '/clients' },
                { title: clientDetailData.profile.name },
                { title: TabsList.at(selectedIndex)?.label || 'Sessions' },
              ]}
            />
          ) : null}
        </section>
        <section className="col-span-4">
          <section className="flex flex-col gap-4">
            <UserInfoCard />
            <UserNotesCard />

            {isLoading ? (
              <ClientOverviewCardLoader variant="card" />
            ) : (
              <UserGoalsCard />
            )}

            {isLoading ? (
              <ClientOverviewCardLoader variant="card" />
            ) : (
              <UserPresentingIssuesCard />
            )}
            {isLoading ? (
              <ClientOverviewCardLoader variant="card" />
            ) : (
              <UserRiskLevelCard />
            )}
            <UserEmergencyContactCard />
            {isLoading ? (
              <ClientOverviewCardLoader variant="card" />
            ) : (
              <UserTagsCard />
            )}
          </section>
        </section>
        <section className="col-span-8 gap-6">
          <section className="flex flex-col gap-4">
            {isLoading ? (
              <ClientOverviewCardLoader variant="credits" />
            ) : (
              <UserCreditsCard />
            )}

            {isLoading ? (
              <ClientOverviewCardLoader variant="tabs" hideCardLayout />
            ) : (
              <>
                <div className="text-black text-displayXs font-semibold">
                  More about Cameron
                </div>
                <Tabs
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  list={TabsList}
                >
                  <TabPanel key={1}>
                    <SessionsList clientId={decodedClientId} />
                  </TabPanel>
                  <TabPanel key={2}>Assignments</TabPanel>
                  <TabPanel key={3}>PI Quiz</TabPanel>
                  <TabPanel key={4}>Helpline Logs</TabPanel>
                </Tabs>
              </>
            )}
          </section>
        </section>
      </section>
    </ClientDetailsContext.Provider>
  );
}
