import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Login from './view/screens/Login';
import Sessions from './view/screens/Sessions';
import Schedule from './view/screens/Schedule';
import Clients from './view/screens/ClientsV2';
import Billing from './view/screens/Billing';
import Assignments from './view/screens/Assignments';
import NotFound from './view/screens/NotFound';
import ProtectedRoutes from './ProtectedRoutes';
import VideoRoom from './view/app-components/VideoRoom';
import ClientDetails from './view/screens/ClientDetails';
import HelplineRecord from './view/screens/HelplineRecord';
import PersonalInsights from './view/screens/PersonalInsightsV2';
import BillingDetails from './view/screens/BillingDetails';
import GetProviderWizard from './view/screens/GetProviderWizard';
import Insights from './view/screens/Insights';
import Settings from './view/screens/Settings';
import CaseNote from './view/app-components/CaseNotesV2';
import CareCaseNotes from './view/app-components/CareCaseNotes';
import ResetPasswordScreen from './view/screens/ResetPassword';
import { Sessions as SessionsV2 } from './v2/view/screens/Sessions/Sessions';
import { LayoutV2 } from './v2/view/screens/Layout/Layout';
import { LayoutV1 } from './view/app-components/LayoutV1';
import ClientDetailsV2 from './v2/view/app-components/ClientDetails/ClientDetailsV2';
import ClientsV2 from './v2/view/screens/Clients';
import { ZoomBase } from './view/app-components/VideoRoom/ZoomRoom/ZoomBase';

function AppRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPasswordScreen />} />
        <Route element={<ProtectedRoutes />}>
          <Route element={<LayoutV1 />}>
            <Route path="/" element={<Sessions />} />
            <Route path="/sessions" element={<Sessions />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/clients" element={<Clients />} />
            <Route
              path="/clients/:serviceType/:clientId"
              element={<ClientDetails />}
            />
            <Route
              path="/clients/:clientId/helpline-records/:helplineRecordId"
              element={<HelplineRecord />}
            />
            <Route
              path="/clients/:clientId/insights"
              element={<PersonalInsights />}
            />
            <Route path="/insights" element={<Insights />} />
            <Route path="/billing" element={<Billing />} />
            <Route
              path="/billing/:billingDay/break-up"
              element={<BillingDetails />}
            />
            <Route path="/assignments" element={<Assignments />} />
            <Route path="/rooms/:channel/:meetingId" element={<VideoRoom />} />
            <Route path="/book-session" element={<GetProviderWizard />} />
            <Route
              path="/carenotes/:clientId/:meetingId"
              element={<CareCaseNotes />}
            />
            <Route
              path="/coachingnotes/:clientId/:meetingId"
              element={<CaseNote />}
            />
            <Route path="/settings" element={<Settings />} />
          </Route>
          <Route element={<LayoutV2 />}>
            <Route path="v2-layout" element={<SessionsV2 />} />
            <Route path="/clients-v2" element={<ClientsV2 />} />
            <Route
              path="/clients-v2/:serviceType/:clientId"
              element={<ClientDetailsV2 />}
            />
          </Route>
          <Route path="/" element={<Sessions />} />
          <Route path="/sessions" element={<Sessions />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/clients" element={<Clients />} />
          <Route
            path="/clients/:serviceType/:clientId"
            element={<ClientDetails />}
          />
          <Route
            path="/clients/:clientId/helpline-records/:helplineRecordId"
            element={<HelplineRecord />}
          />
          <Route
            path="/clients/:clientId/insights"
            element={<PersonalInsights />}
          />
          <Route path="/insights" element={<Insights />} />
          <Route path="/billing" element={<Billing />} />
          <Route
            path="/billing/:billingDay/break-up"
            element={<BillingDetails />}
          />
          <Route path="/assignments" element={<Assignments />} />
          <Route path="/rooms/:channel/:meetingId" element={<VideoRoom />} />
          <Route path="/zoom" element={<ZoomBase />} />
          <Route path="/book-session" element={<GetProviderWizard />} />
          <Route
            path="/carenotes/:serviceType/:clientId/:meetingId"
            element={<CareCaseNotes />}
          />
          <Route
            path="/coachingnotes/:serviceType/:clientId/:meetingId"
            element={<CaseNote />}
          />
          <Route path="/settings" element={<Settings />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AppRoutes;
