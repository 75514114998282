import { variants } from '../../shared/constant/Colors';
import { ProviderRole } from '../../shared/constant/Common';

export const getClientStatus = (status: string) => {
  const clientStatusColors: Record<
    string,
    { label: string; variant: keyof typeof variants }
  > = {
    '0': {
      label: 'inactive',
      variant: 'gray',
    },
    '1': {
      label: 'active',
      variant: 'success',
    },
    '2': {
      label: 'matched',
      variant: 'purple',
    },
    '3': {
      label: 'booked',
      variant: 'warning',
    },
    '4': {
      label: 'UNMATCHED_CLIENTS',
      variant: 'error',
    },
  };

  return clientStatusColors?.[status];
};

export const getProviderRole = (providerRole: string) => {
  const providerRoles: Record<string, { label: string }> = {
    [ProviderRole.COACHING]: {
      label: 'Coaching',
    },
    [ProviderRole.CLINICAL]: {
      label: 'Clinical',
    },
    [ProviderRole.HELPLINE]: {
      label: 'Helpline',
    },
    [ProviderRole.CARENAVIGATOR]: {
      label: 'Care Navigator',
    },
    [ProviderRole.NUTRITIONIST]: {
      label: 'Nutritionist',
    },
    [ProviderRole.FITNESS]: {
      label: 'Fitness',
    },
    [ProviderRole.FINANCIAL]: {
      label: 'Financial',
    },
    [ProviderRole.EXECUTIVE]: {
      label: 'Executive',
    },
    [ProviderRole.LEGAL]: {
      label: 'Coaching',
    },
    [ProviderRole.CAREOFFSITE]: {
      label: 'Care Offsite',
    },
    [ProviderRole.ONSITE]: {
      label: 'Onsite',
    },
  };

  return providerRoles?.[providerRole] ?? 'Provider';
};
