import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './PHQWidget.scss';
import Typography from '../Typography';
import Button from '../Button';
import PhqScoreInfo from '../../app-components/PHQScoreInfo';
import usePersonalInsights from '../../../utilities/hooks/usePersonalInsights';
import ChartWidget from '../Charts/ChartWidget';
import Loader from '../Loader';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../shared/constant/Analytics';
import useTracking from '../../../utilities/hooks/useTracking';

function PHQWidget({ userId }: { userId?: string }) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const [isInfoPopUp, setIsInfoPopUp] = React.useState<boolean>(false);
  const { isPHQDataLoading, phqData } = usePersonalInsights(userId);

  const handleInfoClick = () => {
    setIsInfoPopUp(true);
    track(EventNames.client_personal_insights, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.riskAssessment,
      eventLabel: 'risk_assessment',
      featureVersion: 'v1',
    });
  };
  const handlePopUpClose = () => {
    setIsInfoPopUp(false);
    track(EventNames.client_personal_insights, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.close,
      eventLabel: 'close',
      featureVersion: 'v1',
    });
  };

  const getColor = (score: number, alpha: number = 0.5) => {
    if (score >= 9) {
      return `rgba(219, 85, 85, ${alpha})`;
    }
    if (score >= 6) {
      return `rgba(250, 195, 72, ${alpha})`;
    }
    if (score >= 3) {
      return `rgba(250, 195, 72, ${alpha})`;
    }
    return `rgba(10, 166, 93, ${alpha})`;
  };

  return (
    <article className="PHQ-container">
      <section className="PHQ-header-container">
        <Typography size={18} weight="500" withColor="#0A0C0E">
          {phqData?.title}
        </Typography>
      </section>
      <section style={{ padding: '5px' }}>
        <section className="meter-section">
          {isPHQDataLoading ? (
            <Loader useFullPage={false} />
          ) : (
            <ChartWidget
              type="GaugeChart"
              gaugeChartProps={{
                totalValue: 12,
                currentValue: Number(phqData?.score),
                arcBorderRadius: 10,
                topColor: getColor(Number(phqData?.score)),
                currentMarkerColor: getColor(Number(phqData?.score), 1),
                markerRadius: 1,
                markerColor: 'grey',
              }}
            />
          )}
        </section>
        <section className="PHQ-desc-container">
          <Typography
            size={18}
            weight="500"
            withColor={getColor(Number(phqData?.score), 1)}
          >
            {phqData?.risk}
          </Typography>
          {phqData?.category?.length !== 0 && (
            <Typography
              size={18}
              weight="600"
              withColor={getColor(Number(phqData?.score), 1)}
            >
              ({phqData?.category})
            </Typography>
          )}
          <Button
            label={t('SCORE_MEAN_LABEL')}
            backgroundColor="#F1F3F4"
            variant="info"
            onClick={handleInfoClick}
            width="206px"
            minWidth="206px"
            fontSize={12}
            fontWeight="500"
          />
          {isInfoPopUp && (
            <PhqScoreInfo
              title={phqData?.title}
              onClose={handlePopUpClose}
              currentScore={phqData?.score}
            />
          )}
        </section>
      </section>
    </article>
  );
}

export default PHQWidget;
