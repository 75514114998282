import { useContext } from 'react';
import BasicCardTemplate from '../../components/BasicCardTemplate';
import InfoTile from '../../components/InfoTile';
import { Tag } from '../../components/Tag';
import Tooltip from '../../components/Tooltip';
import ClientDetailsContext from './context/ClientDetailsContext';

export default function UserGoalsCard() {
  const { clientDetailData } = useContext(ClientDetailsContext);

  const clientGoals = clientDetailData?.goals ?? [];

  return (
    <BasicCardTemplate heading="Goals" showToolTip={false}>
      {!clientGoals.length ? (
        <InfoTile
          lineClamp={2}
          variant="gray"
          showIcon
          content="Client hasn’t set any goals yet, please ask them to add goals, so you can better help them achieve those goals."
        />
      ) : (
        <section className="flex flex-wrap gap-2">
          {clientGoals?.map((goal, index) => (
            <>
              <Tag
                key={goal}
                className="truncate max-w-[165px] text-xs text-center"
                variant="primary"
                tooltipId={`${goal}-${index}`}
              >
                {goal}
              </Tag>
              <Tooltip id={`${goal}-${index}`} content={goal} />
            </>
          ))}
        </section>
      )}
    </BasicCardTemplate>
  );
}
