import * as React from 'react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DailyProvider } from '@daily-co/daily-react';
import DailyIframe, { DailyCall } from '@daily-co/daily-js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import { createMachine, assign, fromPromise } from 'xstate';
import Tray from './components/Tray/Tray';
import Call from './components/Call/Call';
import Header from './components/Header/Header';
import HairCheck from './components/HairCheck/HairCheck';
import HomeScreen from './components/HomeScreen/HomeScreen';
import LeaveRoomConfirmPopup from '../../LeaveRoomConfirmPopup';
import { IMeetingStatus } from '../../../../shared/types/response/provider';
import { completeMeeting as _completeMeeting } from '../../../../shared/redux/actions/app';
import './Room.css';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';
import useTracking from '../../../../utilities/hooks/useTracking';
import Typography from '../../../components/Typography';
import useCaseNotesDetails, {
  getAllCaseNoteOptions,
  getCaseNoteFormType,
} from '../../../../utilities/hooks/useCaseNotesDetails/useCaseNotesDetails';
import {
  CaseNoteFormType,
  CaseNoteQuestionTypes,
} from '../../../../shared/constant/CoachingCaseNotes';
import { encodeCode } from '../../../../utilities/common/Encryption';
import PrimaryIssueModal from '../components/PrimaryIssueModal';
import { ICasenoteQuestionData } from '../../CaseNotesV2/types';

const appMachine = createMachine({
  id: 'app',
  initial: 'idle',
  context: {
    roomUrl: undefined,
    callObject: null as DailyCall | null,
    error: null as unknown | string,
  },
  states: {
    idle: {
      entry: assign({ callObject: null, error: null }),
      on: { CREATE_CALL: { target: 'creating' } },
    },
    creating: {
      invoke: {
        src: 'createCall',
        input: ({ context: { roomUrl } }) => ({ roomUrl }),
        onDone: {
          target: 'initializeCallObject',
          actions: assign({ roomUrl: (context) => context.event.output }),
        },
        onError: {
          target: 'exit',
          actions: assign({
            error: (context: { event: { error: { message: string } } }) => {
              Sentry.captureException(context.event.error?.message);
              return context.event.error?.message;
            },
          }),
        },
      },
    },
    initializeCallObject: {
      invoke: {
        src: 'initializeCallObject',
        input: ({ context: { roomUrl } }) => ({ roomUrl }),
        onDone: {
          target: 'haircheck',
          actions: assign({ callObject: (context) => context.event.output }),
        },
        onError: {
          target: 'exit',
          actions: assign({
            error: (context: { event: { error: { message: string } } }) => {
              Sentry.captureException(context.event.error?.message);
              return context.event.error?.message;
            },
          }),
        },
      },
    },
    haircheck: {
      on: {
        JOINED: { target: 'joining' },
        LEFT_ROOM_HAIR_CHECK: { target: 'leftRoomHairCheck' },
      },
    },
    joining: {
      invoke: {
        src: 'joinCall',
        input: ({ context: { roomUrl, callObject } }) => ({
          roomUrl,
          callObject,
        }),
        onDone: {
          target: 'joined',
        },
        onError: {
          target: 'error',
          actions: assign({
            error: (context: { event: { error: { message: string } } }) => {
              Sentry.captureException(context.event.error?.message);
              return context.event.error?.message;
            },
          }),
        },
      },
    },
    joined: {
      on: {
        LEAVE_CALL: 'leaving',
        ASK_PRIMARY_ISSUE: 'askPrimaryIssue',
        ERROR: {
          target: 'error',
          actions: assign({
            error: (context) => {
              Sentry.captureException(context.event.error?.message);
              return context?.event?.error;
            },
          }),
        },
      },
    },
    leaving: {
      on: { LEFT_ROOM: 'leftRoom', CONTINUE_ROOM: 'joined' },
    },
    askPrimaryIssue: {
      on: { LEAVE_CALL: 'leaving', CONTINUE_ROOM: 'joined' },
    },
    leftRoomHairCheck: {
      invoke: {
        src: 'onLeftRoomHairCheck',
        onDone: {
          target: 'idle',
          actions: assign({ callObject: (context) => context.event.output }),
        },
        onError: {
          target: 'error',
          actions: assign({ error: (context, event: any) => event.data }),
        },
      },
    },
    leftRoom: {
      invoke: {
        src: 'leaveCall',
        input: ({ context: { callObject } }) => ({ callObject }),
        onDone: {
          target: 'idle',
          actions: assign({ callObject: (context) => context.event.output }),
        },
        onError: {
          target: 'error',
          actions: assign({
            error: (context, event: any) => {
              Sentry.captureException(event?.data?.message);
              return event.data;
            },
          }),
        },
      },
    },
    error: {
      on: { RETRY: 'joining' },
    },
    exit: {
      on: { EXIT: 'leftRoom' },
    },
  },
});

function App({
  meeting,
  completeMeeting,
}: {
  meeting?: IMeetingStatus;
  completeMeeting: Function;
}) {
  const { track } = useTracking();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const caseNotesData = useCaseNotesDetails({
    meetingId: meeting?.data?.meetingId as string,
  });

  const { fetchCasenoteFormType } = getCaseNoteFormType((data) => {
    if (
      data.form === CaseNoteFormType.V3 ||
      data.form === CaseNoteFormType.V4
    ) {
      navigate(
        `/carenotes/${meeting?.data?.providerRole}/${encodeCode(
          Number(meeting?.data?.clientId),
        )}/${meeting?.data?.meetingId}`,
      );
    } else if (data.form === CaseNoteFormType.V1) {
      navigate(
        `/coachingnotes/${meeting?.data?.providerRole}/${encodeCode(
          Number(meeting?.data?.clientId),
        )}/${meeting?.data?.meetingId}`,
      );
    } else {
      navigate('/sessions');
    }
  });

  const [state, send] = useMachine(
    appMachine.provide({
      actors: {
        createCall: fromPromise(async () => {
          const url = searchParams.get('roomUrl');
          console.log('room-url', url);
          if (!url) throw new Error('Room URL not found');
          return url;
        }),
        initializeCallObject: fromPromise(async ({ input }: { input: any }) => {
          try {
            const oldCallObject = DailyIframe.getCallInstance();
            if (oldCallObject) await oldCallObject.destroy();
            const newCallObject = DailyIframe.createCallObject();
            await newCallObject.preAuth({ url: input?.roomUrl });
            await newCallObject.startCamera();
            return newCallObject;
          } catch (error: any) {
            throw new Error(error?.errorMsg);
          }
        }),
        leaveCall: fromPromise(async ({ input }: { input: any }) => {
          await input?.callObject?.leave();
          await input?.callObject?.destroy();
        }),
        onLeftRoomHairCheck: fromPromise(async () => {
          navigate('/sessions');
        }),
        joinCall: fromPromise(async ({ input }: { input: any }) => {
          console.log('join', input);
          track(EventNames.inSession, {
            eventAction: EventActions.click,
            eventCategory: EventCategories.joinCall,
            eventLabel: 'join_call',
            featureVersion: 'v1',
          });
          try {
            await input.callObject?.join({ url: input?.roomUrl });
          } catch (error: any) {
            throw new Error(error?.errorMsg);
          }
        }),
      },
    }),
  );

  const {
    refetchPrimaryIssueStatus,
    saveNewcaseNotes,
    newCaseNoteQuestions,
    isQuestionaireLoading,
  } = useCaseNotesDetails({
    meetingId: meeting?.data?.meetingId as string,
    afterAddingCaseNotes: () => {
      send({ type: 'LEAVE_CALL' });
    },
  });

  const caseNoteQuestionsData = React.useMemo(() => {
    const questions: ICasenoteQuestionData = {};
    newCaseNoteQuestions?.forEach((question: any) => {
      questions[question.type] = {
        question: question.question,
        id: question.id,
      };
    });
    return questions;
  }, [isQuestionaireLoading, newCaseNoteQuestions]);

  const followupCaseNotesOptions = getAllCaseNoteOptions(
    caseNotesData?.newCaseNoteAnswers?.form as string,
    meeting?.data?.providerRole as string,
  );

  const { issuesOptions: presentingIssueOptions } = followupCaseNotesOptions;

  React.useEffect(() => {
    const url = new URLSearchParams(window.location.search).get('roomUrl');
    if (url) send({ type: 'CREATE_CALL' });
  }, [send]);

  const renderApp = () => {
    console.log('render app state', state);
    if (state.matches('error') || state.matches('exit')) {
      return (
        <div className="api-error">
          <Typography size={16} weight="600" color="error">
            {state.context.error === 'Meeting is full'
              ? t('JOIN_FROM_ONE_DEVICE')
              : Error}
          </Typography>
          <Typography size={12} color="light" textAlign="left">
            {state.context.error === 'Meeting is full'
              ? t('MEETING_FULL_ERR')
              : state.context.error}
          </Typography>
          <button
            onClick={() =>
              send({ type: state.matches('exit') ? 'EXIT' : 'RETRY' })
            }
            className="retry-btn"
            type="button"
          >
            {state.matches('exit') ? 'Exit' : 'Retry'}
          </button>
        </div>
      );
    }

    if (state.matches('joining')) {
      return (
        <div className="joining-call-screen">
          <Typography size={12} color="light" textAlign="left">
            {t('ROOM_CREATION_IN_PROGRESS')}
          </Typography>
        </div>
      );
    }

    if (!DailyIframe.supportedBrowser().supported) {
      return (
        <div className="unsupported-error">
          <Typography size={16} color="error" weight="600">
            {t('browserNotSupported')}
          </Typography>
        </div>
      );
    }

    if (state.matches('haircheck')) {
      console.log('state', state.context);
      return (
        <DailyProvider callObject={state.context.callObject}>
          <HairCheck
            joinCall={() => send({ type: 'JOINED' })}
            cancelCall={() => send({ type: 'LEFT_ROOM_HAIR_CHECK' })}
          />
        </DailyProvider>
      );
    }

    if (state.matches('joined')) {
      return (
        <DailyProvider callObject={state.context.callObject}>
          <Call
            onError={(errorMsg: string) =>
              send({ type: 'ERROR', error: errorMsg })
            }
          />
          <Tray
            leaveCall={async () => {
              const primaryStatus = await refetchPrimaryIssueStatus();
              if (primaryStatus?.data?.notes?.length === 0) {
                send({ type: 'ASK_PRIMARY_ISSUE' });
              } else {
                send({ type: 'LEAVE_CALL' });
              }
            }}
            meeting={meeting}
          />
        </DailyProvider>
      );
    }

    if (state.matches('askPrimaryIssue')) {
      return (
        <PrimaryIssueModal
          meetingId={meeting?.data.meetingId || ''}
          show
          onClose={() => {
            send({ type: 'CONTINUE_ROOM' });
          }}
          onSave={(val) => {
            saveNewcaseNotes({
              meetingId: meeting?.data.meetingId,
              userId: Number(meeting?.data?.clientId),
              caseNotes: [
                {
                  questionId:
                    caseNoteQuestionsData?.[CaseNoteQuestionTypes.PrimaryGoal]
                      .id,
                  note: val.value,
                },
              ],
            });
          }}
          primaryOptions={presentingIssueOptions}
        />
      );
    }

    if (state.matches('leaving')) {
      return (
        <LeaveRoomConfirmPopup
          onStayHereClick={() => send({ type: 'CONTINUE_ROOM' })}
          onLeaveClick={() => {
            completeMeeting();
            track(EventNames.inSession, {
              eventAction: EventActions.load,
              eventCategory: EventCategories.meetingFinish,
              eventLabel: 'meeting_finish',
              featureVersion: 'v1',
            });
            fetchCasenoteFormType({
              meetingId: meeting?.data.meetingId,
              meetingDate: meeting?.data.meetingDate,
              isInSession: false,
            });
            send({ type: 'LEFT_ROOM' });
          }}
        />
      );
    }

    return (
      <HomeScreen
        createCall={() => send({ type: 'CREATE_CALL' })}
        startHairCheck={() => send({ type: 'START_HAIRCHECK' })}
      />
    );
  };

  return (
    <div className="app">
      {/* @ts-ignore */}
      <Header meetingData={meeting?.data} />
      {renderApp()}
    </div>
  );
}

const mapStateToProps = (state: any) => ({ meeting: state.app.meeting });
const mapDispatchToProps = (dispatch: Function) => ({
  completeMeeting: () => dispatch(_completeMeeting()),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
