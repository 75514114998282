import { ReactComponent as NoDataFoundIcon } from '../../../../assets/NoDataFound.svg';

export default function NoSessionsFound() {
  return (
    <div className="flex justify-center items-center w-full flex-col gap-2">
      <div>
        <NoDataFoundIcon />
      </div>
      <div className="text-displayXs font-semibold text-gray-900">
        No sessions found
      </div>
      <div className="max-w-md text-center text-sm text-gray-500">
        Oops we didn’t find any sessions, please book a session to view booked
        and past sessions
      </div>
    </div>
  );
}
