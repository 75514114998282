import { useContext, useEffect, useMemo, useState } from 'react';
import { Button } from '../../components/Button';
import Modal from '../../components/Modal';
import SelectableRiskLevelCard from './SelectableRiskLevelCard';
import { RiskLevelsMappings } from '../../../shared/constant/Clients';
import {
  useAddClientRiskLevel,
  useEditClientRiskLevel,
} from './hooks/UseClientRiskLevel';
import ClientDetailsContext from './context/ClientDetailsContext';
import { IClientRiskLevel } from './types/client';

interface AddRiskLevelModalProps {
  open: boolean;
  existingRiskLevel?: IClientRiskLevel;
  handleClose: () => void;
  handleSuccess: () => void;
}
function AddRiskLevelModal({
  open,
  handleClose,
  handleSuccess,
  existingRiskLevel,
}: AddRiskLevelModalProps) {
  const [selectedRiskLevel, setSelectedRiskLevel] = useState(
    existingRiskLevel?.risk ?? '',
  );
  const { decodedClientId, serviceType, clientDetailData } =
    useContext(ClientDetailsContext);

  useEffect(() => {
    setSelectedRiskLevel(existingRiskLevel?.risk ?? '');
  }, [existingRiskLevel]);

  const handleClick = (risk: string) => {
    setSelectedRiskLevel(risk);
  };

  const onClose = () => {
    setSelectedRiskLevel('');
    handleClose();
  };

  const handleApiSuccess = () => {
    onClose();
    handleSuccess();
  };

  const { mutate: addRiskLevel, isLoading: isAddRiskLevelLoading } =
    useAddClientRiskLevel(
      {
        risk: selectedRiskLevel,
        userId: decodedClientId ? +decodedClientId : undefined,
        role: serviceType,
      },
      handleApiSuccess,
      decodedClientId,
    );

  const { mutate: editRiskLevel, isLoading: isEditRiskLevelLoading } =
    useEditClientRiskLevel(
      { risk: selectedRiskLevel },
      handleApiSuccess,
      decodedClientId,
      existingRiskLevel?.id,
    );

  const handleSubmit = () => {
    if (existingRiskLevel?.id) {
      editRiskLevel();
    } else {
      addRiskLevel();
    }
  };

  const FilteredRiskLevelsBasedOnRole = useMemo(() => {
    const providerRole = clientDetailData?.profile?.providerRole;

    if (!providerRole) return [];

    return RiskLevelsMappings.filter((item) =>
      item.role.includes(providerRole),
    );
  }, [clientDetailData?.profile?.providerRole]);

  return (
    <Modal
      open={open}
      handleClose={onClose}
      title="Select risk level"
      className="w-5/12"
    >
      <section className="flex flex-col gap-4 max-h-[572px] overflow-scroll">
        {FilteredRiskLevelsBasedOnRole.map((riskLevel) => (
          <SelectableRiskLevelCard
            variant={riskLevel.value}
            key={riskLevel.label}
            selected={selectedRiskLevel === riskLevel.value}
            handleClick={handleClick}
          />
        ))}
      </section>
      <section className="flex flex-col gap-4">
        <hr />
        <Button
          disabled={
            !selectedRiskLevel ||
            isAddRiskLevelLoading ||
            isEditRiskLevelLoading
          }
          onClick={handleSubmit}
        >
          Save
        </Button>
      </section>
    </Modal>
  );
}

export default AddRiskLevelModal;
