import * as React from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment';
import './DatePicker.scss';
import DatePickerInput from './components/DatePickerInput';
import DatePickerPopper from './components/DatePickerPopper/DatePickerPopper';
import { APIDateFormat } from '../../../utilities/common/Date';

interface DatePickerProps {
  onSelect: (date: string) => void;
  enabledDates?: string[];
  customClass?: string;
  inputDateFormat?: string;
  daysToDisable?: number;
  disableInput?: boolean;
}

function DatePicker({
  onSelect,
  enabledDates,
  customClass,
  daysToDisable,
  disableInput = false,
  inputDateFormat = 'MMM DD, YYYY - dddd',
}: DatePickerProps) {
  const popperSideMargin = 16; // in px
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [parentRect, setParentRect] = React.useState<DOMRect | undefined>();
  const [selectedDate, setSelectedDate] = React.useState<string>('');
  const [showDatePickerPopper, setShowDatePickerPopper] =
    React.useState<boolean>(false);

  const onSelectDate = (date: string) => {
    setSelectedDate(date);
    onSelect(date);
  };

  React.useEffect(() => {
    if (parentRef.current) {
      const rect = parentRef.current?.getBoundingClientRect();
      setParentRect(rect);
    }
  }, [parentRef.current]);

  return (
    <div
      className={`date-picker-container ${customClass && customClass}`}
      ref={parentRef}
    >
      <DatePickerInput
        onClick={() => !disableInput && setShowDatePickerPopper(true)}
        value={
          selectedDate &&
          moment(selectedDate, APIDateFormat).format(inputDateFormat)
        }
      />
      {createPortal(
        <AnimatePresence>
          {showDatePickerPopper && parentRect && (
            <DatePickerPopper
              enabledDates={enabledDates}
              top={parentRect.top || 0}
              left={parentRect.right + popperSideMargin || 0}
              onSelectDate={(date) => onSelectDate(date)}
              onCancel={() => setShowDatePickerPopper(false)}
              daysToDisable={daysToDisable}
            />
          )}
        </AnimatePresence>,
        document.body,
      )}
    </div>
  );
}

export default DatePicker;
