import moment from 'moment';
import { useContext, useState } from 'react';
import { riskColorVariants } from '../../../shared/constant/Colors';
import { ClientProfileDateFormat } from '../../../utilities/common/Date';
import BasicCardTemplate from '../../components/BasicCardTemplate';
import AddRiskLevelModal from './AddRiskLevelModal';
import ClientOverviewCardLoader from './ClientOverviewCardLoader';
import ClientDetailsContext from './context/ClientDetailsContext';
import { useClientRiskLevel } from './hooks/UseClientRiskLevel';
import RiskEmptyState from './RiskEmptyState';
import RiskLabel from './RiskLabels';
import { ClientStatus } from '../../../shared/constant/Clients';

export default function UserRiskLevelCard() {
  const [showRiskLevelModal, setShowRiskLevelModal] = useState<boolean>(false);

  const { decodedClientId, serviceType, isLoading, clientDetailData } =
    useContext(ClientDetailsContext);

  const {
    data: clientRiskAssessments,
    isLoading: isRiskLoading,
    refetch: refetchRisk,
  } = useClientRiskLevel(decodedClientId, serviceType);

  // picking recent note
  const existingRisk = clientRiskAssessments?.[0];

  const isLocked =
    clientDetailData?.profile?.status === ClientStatus.UNMATCHED_CLIENTS;

  return isLoading || isRiskLoading ? (
    <ClientOverviewCardLoader variant="card" />
  ) : (
    <>
      <BasicCardTemplate heading="Risk level" showToolTip>
        {!existingRisk ? (
          <RiskEmptyState
            onClick={() => setShowRiskLevelModal(true)}
            isLocked={isLocked}
          />
        ) : (
          <RiskLabel
            riskLevel={riskColorVariants?.[existingRisk?.risk]?.label}
            subText={moment(existingRisk?.updatedAt).format(
              ClientProfileDateFormat,
            )}
            variant={existingRisk?.risk ?? ''}
            onClick={() => setShowRiskLevelModal(true)}
            isLocked={isLocked}
          />
        )}
      </BasicCardTemplate>
      <AddRiskLevelModal
        open={showRiskLevelModal}
        handleClose={() => setShowRiskLevelModal(false)}
        handleSuccess={() => refetchRisk()}
        existingRiskLevel={existingRisk}
      />
    </>
  );
}
