import { Dialog, Transition } from '@headlessui/react';
import { Fragment, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { Button } from './Button';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  title?: string;
  subtitle?: string;
  className?: string;
}

export default function Modal({
  open,
  handleClose,
  title,
  subtitle,
  children,
  className,
}: PropsWithChildren<ModalProps>) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={() => handleClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={twMerge(
                  'w-md max-w-[90vw] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all',
                  className,
                )}
              >
                <div className="flex gap-2 items-center justify-between">
                  <div className="w-full">
                    {title ? (
                      <Dialog.Title
                        as="h3"
                        className="text-displayXs w-full flex items-center justify-between gap-3 font-semibold leading-7 text-gray-900 w-100"
                      >
                        <div>{title}</div>
                        <Button
                          variant="ghost"
                          className="w-8 h-8 p-2 focus:bg-gray-200"
                          onClick={handleClose}
                          autoFocus={false}
                        >
                          <CloseIcon className="text-gray-600" />
                        </Button>
                      </Dialog.Title>
                    ) : null}
                    {subtitle ? (
                      <div className="text-gray-600 mt-0.5 leading-5 text-sm">
                        {subtitle}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="mt-5">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
