import { useInfiniteQuery } from 'react-query';
import { SessionListResponse } from '../types/session';
import { getSessionsWithClient } from '../../../../shared/service/API/session';
import { SessionListPageSize } from '../constants';

export function useSessionsList(
  clientId: string,
  role?: string,
  startingPage: number = 1,
) {
  return useInfiniteQuery<SessionListResponse>({
    queryKey: ['sessionsList', startingPage, clientId, role],
    queryFn: ({ pageParam = startingPage }) =>
      getSessionsWithClient(
        { role, page: pageParam, pageSize: SessionListPageSize },
        clientId,
      ),
    getNextPageParam: (lastPage) =>
      lastPage.page ? lastPage.page + 1 : undefined,
    refetchOnWindowFocus: false,
  });
}
