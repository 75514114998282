import { MeetingStatus } from '../../../shared/constant/Session';

export const MeetingStatusTags = {
  '3': 'Cancelled',
  '6': 'User last min cancelled',
  '7': 'Provider no show',
  '8': 'User no show',
  '10': 'Request cancelled',
} satisfies Partial<
  Record<(typeof MeetingStatus)[keyof typeof MeetingStatus], string | null>
>;

export const SessionListPageSize = 10;
