import { useState } from 'react';
import { toast } from 'sonner';
import { Button } from '../../components/Button';
import { ReactComponent as PersonIcon } from '../../../assets/person.svg';
import { ReactComponent as SearchIcon } from '../../../assets/search.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/logout.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit.svg';
import { Select } from '../../components/Select';
import { Input } from '../../components/Input';
import { Tag } from '../../components/Tag';
import { Menu, MenuItem } from '../../components/Menu';
import { Alert } from '../../components/Alert';
import UserInfoCard from '../../app-components/ClientDetails/UserInfoCard';
import DateBadge from '../../components/DateBadge';
import InfoTile from '../../components/InfoTile';
import RiskLabel from '../../app-components/ClientDetails/RiskLabels';
import { riskColorVariants } from '../../../shared/constant/Colors';
import BasicCardTemplate from '../../components/BasicCardTemplate';

const options = [
  { label: 'Sunil Chhetri', value: 'sunil_chhetri' },
  { label: 'Amrinder Singh', value: 'amrinder_singh' },
  { label: 'Prabhsukhan Singh Gill', value: 'prabhsukhan_singh_gill' },
  { label: 'Gurpreet Singh Sandhu', value: 'gurpreet_singh_sandhu' },
  { label: 'Anwar Ali', value: 'anwar_ali' },
  { label: 'Mehtab Singh', value: 'mehtab_singh' },
  { label: 'Chinglensana Singh', value: 'chinglensana_singh' },
  { label: 'Subhasish Bose', value: 'subhasish_bose' },
  { label: 'Jay Gupta', value: 'jay_gupta' },
  { label: 'Naorem Roshan Singh', value: 'naorem_roshan_singh' },
  { label: 'Asish Rai', value: 'asish_rai' },
  { label: 'Nikhil Poojary', value: 'nikhil_poojary' },
  { label: 'Rahul Bheke', value: 'rahul_bheke' },
  { label: 'Jeakson Singh', value: 'jeakson_singh' },
  { label: 'Suresh Singh', value: 'suresh_singh' },
  { label: 'Apuia', value: 'apuia' },
  { label: 'Anirudh Thapa', value: 'anirudh_thapa' },
  { label: 'Puitea', value: 'puitea' },
  { label: 'Sahal Abdul Samad', value: 'sahal_abdul_samad' },
  { label: 'Naorem Mahesh Singh', value: 'naorem_mahesh_singh' },
  { label: 'Nandha Kumar Sekar', value: 'nandha_kumar_sekar' },
  { label: 'Lallianzuala Chhangte', value: 'lallianzuala_chhangte' },
];

export function Sessions() {
  const [selectedSingle, setSelectedSingle] = useState<string>();
  const [selectedMultiple, setSelectedMultiple] = useState<string[]>();

  console.log(selectedSingle, selectedMultiple, 'selected');

  return (
    <div className="h-[200vh] px-5 py-12 overflow-auto">
      <div className="flex justify-end">
        <Menu
          render={
            <img
              src="https://d3imeoj536zhqj.cloudfront.net/images/uploads/emily-test%201.png"
              width={50}
              alt="user-menu"
            />
          }
          className="rounded-full overflow-hidden m-r-0"
        >
          <MenuItem
            label="Log out"
            icon={
              <LogoutIcon
                className="text-error-500"
                height="0.875rem"
                fontWeight={500}
              />
            }
            className="text-error-500"
          />
          <MenuItem
            label="View profile"
            icon={<PersonIcon height="0.875rem" />}
          />
        </Menu>
      </div>

      <div className="text-xs">Upcoming Sessions</div>
      <div className="text-sm">Upcoming Sessions</div>
      <div className="text-lg">Upcoming Sessions</div>
      <div className="text-xl">Upcoming Sessions</div>
      <div className="text-displayXs">Upcoming Sessions</div>
      <div className="text-displaySm">Upcoming Sessions</div>
      <div className="text-displayMd font-bold">Upcoming Sessions</div>
      <div className="text-displayXl font-bold">Upcoming Sessions</div>
      <div className="text-display2xl font-bold">Upcoming Sessions</div>
      <div className="grid grid-cols-4 gap-2">
        <div className="flex flex-col gap-2 items-start my-2">
          <Button
            variant="primary"
            onClick={() =>
              toast.error('Error toast. You have messed up somewhere.')
            }
          >
            Click for error toast
          </Button>
          <Button
            variant="secondary"
            onClick={() =>
              toast.success(
                'Success toast! You have not messed up somewhere! (yet)',
              )
            }
          >
            Click for success toast
          </Button>
          <Button
            variant="tertiary"
            onClick={() =>
              toast.warning(
                'Warning toast. You are going to mess up somewhere.',
              )
            }
          >
            Click for warning toast
          </Button>
          <Button
            variant="ghost"
            onClick={() =>
              toast.info(
                'Info toast. There is a chance you will mess up somewhere.',
              )
            }
          >
            {' '}
            Click for info toast
          </Button>
          <Button
            className="text-left w-48"
            variant="secondary"
            startIcon={<PersonIcon />}
            endIcon={<Tag>6</Tag>}
          >
            Click me
          </Button>
        </div>
        <div className="flex flex-col gap-2 items-start my-2">
          <Button variant="primary" disabled>
            Click me
          </Button>
          <Button variant="secondary" disabled>
            Click me
          </Button>
          <Button variant="tertiary" disabled>
            Click me
          </Button>
          <Button variant="ghost" disabled>
            Click me
          </Button>
        </div>
        <div className="flex flex-col gap-2 items-start my-2">
          <Button variant="primary" mini>
            Click me
          </Button>
          <Button variant="secondary" mini>
            Click me
          </Button>
          <Button mini variant="tertiary">
            Click me
          </Button>
          <Button mini variant="ghost">
            Click me
          </Button>
          <Button
            variant="secondary"
            mini
            startIcon={<PersonIcon />}
            endIcon={<Tag className="text-xs">6</Tag>}
          >
            Click me
          </Button>
        </div>
        <div className="flex flex-col gap-2 items-start my-2">
          <Button variant="primary" mini disabled>
            Click me
          </Button>
          <Button variant="secondary" mini disabled>
            Click me
          </Button>
          <Button mini variant="tertiary" disabled>
            Click me
          </Button>
          <Button mini variant="ghost" disabled>
            Click me
          </Button>
        </div>
      </div>

      <div className="flex gap-3 items-center mt-8">
        <Select
          options={options}
          selected={selectedSingle || ''}
          onSelect={setSelectedSingle}
        />
        <Select
          multiple
          options={options}
          selected={selectedMultiple || []}
          onSelect={setSelectedMultiple}
        />
        <Select
          options={options}
          selected={selectedSingle || ''}
          onSelect={setSelectedSingle}
          searchable
        />
        <Select
          multiple
          options={options}
          selected={selectedMultiple || []}
          onSelect={setSelectedMultiple}
          searchable
        />
      </div>

      <Input
        containerClassName="mt-5"
        startIcon={<SearchIcon className="w-4 text-gray-400" />}
        endIcon={<PersonIcon />}
      />

      <div className="mt-3 flex items-center gap-3">
        <Tag className="font-semibold">6</Tag>
        <Tag>Primary</Tag>
        <Tag variant="gray">Secondary</Tag>
        <Tag variant="warning">Warning</Tag>
        <Tag variant="success">Success</Tag>
        <Tag variant="error">Error</Tag>
        <Tag variant="purple">Purple</Tag>
      </div>
      <div className="mt-3 flex items-center gap-3">
        <Alert title="test">Test alert data</Alert>
        <Alert
          variant="warning"
          button={{ icon: <EditIcon />, onClick: () => {} }}
          title="test"
          icon={<PersonIcon />}
        >
          Test alert data
        </Alert>
        <Alert variant="error" title="test" icon={<PersonIcon />}>
          Test alert data
        </Alert>
        <Alert variant="rose" title="test" icon={<PersonIcon />}>
          Test alert data
        </Alert>
      </div>
      <div className="mt-3 flex items-center gap-3 flex-wrap">
        {[0, 1, 2, 3, 4].map((item) => (
          <UserInfoCard key={item} />
        ))}
      </div>
      <div className="mt-3 flex items-center gap-3 flex-wrap">
        <DateBadge timeStamp={1726046932} />
        <BasicCardTemplate heading="Presenting Issues" showToolTip={false}>
          <InfoTile
            color="#D92D20"
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.sit amet, consectetur adipiscing elit, sedsit amet, consectetur adipiscing elit, sedsit amet, consectetur adipiscing elit, sed"
          />
        </BasicCardTemplate>
        {Object.keys(riskColorVariants).map((item: any) => (
          <BasicCardTemplate heading="Risk Label" showToolTip={false}>
            <RiskLabel riskLevel={item} subText={item} variant={item} />
          </BasicCardTemplate>
        ))}
      </div>
    </div>
  );
}
