import moment from 'moment';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Button } from '../../../components/Button';
import DateBadge from '../../../components/DateBadge';
import { ReactComponent as ChevronRightIcon } from '../../../../assets/rightChevron.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/clock.svg';
import { ReactComponent as VideoIcon } from '../../../../assets/videoRecorder.svg';
import { ReactComponent as LocationIcon } from '../../../../assets/location.svg';
import { ReactComponent as RedirectionIcon } from '../../../../assets/slanting arrow.svg';
import { ReactComponent as RoomIcon } from '../../../../assets/home.svg';
import UnOrderedList, {
  UnorderedListProps,
} from '../../../components/UnOrderedList';
import { ISessionListItem } from '../types/session';
import { getCurrentLocale } from '../../../../../i18n';
import { getVideoTagStyle } from '../../../../utilities/common/Session';
import { getCaseNoteFormType } from '../../../../../utilities/hooks/useCaseNotesDetails/useCaseNotesDetails';
import { CaseNoteFormType } from '../../../../../shared/constant/CoachingCaseNotes';
import {
  MeetingStatus,
  MeetingStatusLabels,
} from '../../../../shared/constant/Session';
import { Tag } from '../../../components/Tag';

const getStatusTag = (meetingData: ISessionListItem) => {
  switch (meetingData.status) {
    case MeetingStatus.completed:
      return meetingData.presentingIssue ? null : (
        <Tag variant="errorOutline">Pending presenting Issue</Tag>
      );

    case MeetingStatus.providernoshow:
    case MeetingStatus.providerlastmincancelled:
    case MeetingStatus.autocancelled:
    case MeetingStatus.cancelled:
    case MeetingStatus.usernoshow:
    case MeetingStatus.userlastmincancelled:
      return (
        <Tag variant="grayOutline">{MeetingStatusLabels[meetingData.status]}</Tag>
      );
    default:
      return null;
  }
};
interface SessionCardProps {
  data: ISessionListItem;
  className?: string;
}

function SessionCard({ className, data }: SessionCardProps) {
  // const { track } = useTracking();
  const navigate = useNavigate();

  const dispTime: Record<string, string> = {
    ja: `${moment(+data.scheduledStartTime * 1000).format('HH:mm')} - ${moment(
      +data.scheduledEndTime * 1000,
    ).format('HH:mm')}`,
    en: `${data.displayTime} - ${data.displayTimeEnd}`,
  };

  const sessionCardItems: UnorderedListProps['items'] = [
    {
      label: dispTime[getCurrentLocale()],
      startIcon: <ClockIcon className="w-3 h-3" />,
    },
    {
      label: getVideoTagStyle(data.providerRole, data.type).name,
      startIcon: <VideoIcon className="w-3 h-3" />,
    },
  ];

  const sessionLocation: UnorderedListProps['items'] = isEmpty(data.location)
    ? []
    : [
        {
          label: data.location.room,
          startIcon: <RoomIcon className="w-3 h-3" />,
        },
        {
          label: data.location.name,
          startIcon: <LocationIcon className="w-3 h-3" />,
          endIcon: (
            <RedirectionIcon
              stroke="#667085"
              onClick={() => window.open(data.location.redirectUrl)}
            />
          ),
        },
      ];

  const { fetchCasenoteFormType, isFormTypeLoading } = getCaseNoteFormType(
    (formData, variables) => {
      // if (data.form === CaseNoteFormType.v0) {
      //   setSelectedCaseNote({
      //     clientId: userId?.toString(),
      //     meetingId: variables.meetingId,
      //     meetingDate: variables.meetingDate,
      //   });
      // }
      if (formData.form === CaseNoteFormType.V1) {
        navigate(`/coachingnotes/${data.userId}/${variables.meetingId}`);
      } else {
        navigate(`/carenotes/${data.userId}/${variables.meetingId}`);
      }
    },
  );
  const handleDetailsClick = (meetingId: string, meetingDate: string) => {
    // track(EventNames.clientView, {
    //   eventAction: EventActions.click,
    //   eventCategory: EventCategories.view_session_case_notes,
    //   eventLabel: 'view_session_case_notes',
    //   featureVersion: 'v1',
    //   eventSource: EventSource.clientView,
    // });
    // TODO beacon instrumentation
    fetchCasenoteFormType({ meetingId, meetingDate });
  };

  return (
    <section
      className={twMerge(
        'w-full bg-white p-5 box-border flex shadow-sm items-center justify-between border border-solid border-gray-200 rounded-lg ',
        className,
      )}
    >
      <section className="w-full box-border flex items-start gap-4">
        <DateBadge timeStamp={Number(data.scheduledStartTime)} />
        <section className="w-[95%] flex flex-col gap-2 ">
          <section className="w-full flex items-center gap-2 ">
            <section className="text-md font-semibold text-gray-700">
              {data.presentingIssue || 'Untitled session'}
            </section>
            {getStatusTag(data)}
          </section>
          <UnOrderedList
            items={sessionCardItems}
            className="leading-[12px] text-sm font-normal"
          />
          {sessionLocation.length ? (
            <UnOrderedList
              items={sessionLocation}
              className="leading-[12px] text-sm font-normal"
            />
          ) : null}
        </section>
      </section>
      <Button
        variant="ghost"
        disabled={isFormTypeLoading}
        onClick={() => handleDetailsClick(data.id, data.scheduledDate)}
      >
        <ChevronRightIcon />
      </Button>
    </section>
  );
}

export default SessionCard;
