import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as InfoIcon } from '../../assets/infoCircleIcon.svg';
import { infoTileVariants } from '../../shared/constant/Colors';

const clampAfter = {
  1: 'line-clamp-1',
  2: 'line-clamp-2',
  3: 'line-clamp-3',
};
interface InfoTileProps {
  variant?: keyof typeof infoTileVariants;
  content: string | JSX.Element;
  className?: string;
  showIcon?: boolean;
  color?: string;
  subContent?: string | JSX.Element;
  lineClamp?: keyof typeof clampAfter;
  expandable?: boolean;
}

export default function InfoTile({
  variant = 'error',
  content,
  className,
  showIcon,
  color = 'gray',
  subContent,
  lineClamp = 2,
  expandable = false,
}: InfoTileProps) {
  const [showReadMore, setShowReadMore] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const notesContentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (notesContentRef?.current) {
      if (
        notesContentRef.current.offsetHeight <
          notesContentRef.current.scrollHeight ||
        notesContentRef.current.offsetWidth <
          notesContentRef.current.scrollWidth
      ) {
        setShowReadMore(true);
      } else {
        setShowReadMore(false);
      }
    }
  }, [content]);

  return (
    <section
      className={twMerge(
        'box-border w-full p-3 rounded-lg border border-solid flex flex-col gap-1.5 items-center justify-between',
        infoTileVariants[variant],
        className,
      )}
    >
      <section className="w-full flex items-start gap-2">
        {showIcon && <InfoIcon className="w-3 h-3 mt-1" stroke={color} />}
        <div
          ref={notesContentRef}
          className={twMerge(
            'text-xs w-[98%]',
            !isExpanded ? clampAfter[lineClamp] : '',
            'break-words',
          )}
          style={{ color }}
        >
          {content}
        </div>
      </section>
      {expandable && showReadMore && !isExpanded ? (
        <span
          className="text-xs w-[100%] underline"
          role="button"
          tabIndex={0}
          onClick={() => setIsExpanded(true)}
        >
          Read More
        </span>
      ) : null}

      <section
        className="text-[10px] font-normal text-gray-500 leading-[10px] w-full"
        style={{ color }}
      >
        {subContent}
      </section>
    </section>
  );
}
