import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Row } from '@tanstack/react-table';
import { IClientSummary } from '../../../../shared/types/response/provider';
import useTracking from '../../../../utilities/hooks/useTracking';
import Typography from '../../../../view/components/Typography';
import './ClientsV2.scss';
import TextBox from '../../../../view/components/TextBox';
import searchIcon from '../../../assets/search.svg';
import MultiSelect from '../../../../view/components/MultiSelect';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';
import {
  sortFieldNameFromColumnId,
  statusOptions,
  unMatchedStatusCode,
} from './constants';
import Table from '../../../../view/components/Table';
import { clientsColDefinition } from './ClientsColumnDef';
import NoResultsFound from '../../../../view/app-components/NoResultsFound';
import noSearchResults from '../../../../assets/noSerach.svg';
import { encodeCode } from '../../../../utilities/common/Encryption';
import Pagination from '../../../../view/components/ClientPagination/ClientPagination';
import { useClientsList } from './hooks/useClientsList';
import useDebounce from '../../../../utilities/hooks/useDebounce';
import { useClientCompanyOptions } from './hooks/useClientCompanyOptions';

function Clients() {
  const { track } = useTracking();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchText, setSearchText] = React.useState<string>('');
  const debouncedSearchText = useDebounce(searchText, 500);

  const [organisationFilter, setOrgnisationFilter] = React.useState<string[]>(
    [],
  );
  const [statusFilter, setStatusFilter] = React.useState<string[]>([]);

  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [sort, setSort] = React.useState<Record<string, string>>({});

  const { data, isLoading: clientsListLoading } = useClientsList({
    filters: {
      name: debouncedSearchText,
      status: statusFilter.length ? statusFilter : ['1', '2', '3'],
      companyName: organisationFilter,
    },
    currentPage,
    sort,
  });

  const { data: organisationOptions } = useClientCompanyOptions();

  React.useEffect(() => {
    track(EventNames.providerWebClients, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.viewClients,
      eventLabel: 'view_clients',
    });
  }, []);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [statusFilter, organisationFilter, debouncedSearchText]);

  const onTableRowClick = (rowData: Row<IClientSummary>) => {
    const clientData = rowData.original;
    const { userId, providerRole } = clientData;
    navigate(`/clients-v2/${providerRole}/${encodeCode(userId)}`);
  };

  const onSortClick = (colName: string, sortDir: string | false) => {
    track(EventNames.providerWebClients, {
      eventAction: EventActions.click,
      eventCategory: colName,
      eventLabel: sortDir || 'default',
      featureVersion: 'v1',
    });

    const fieldName =
      sortFieldNameFromColumnId[
        colName as keyof typeof sortFieldNameFromColumnId
      ];

    if (sortDir === false) {
      setSort({});
    } else {
      setSort({ [fieldName]: sortDir.toUpperCase() });
    }
  };

  const onPageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const onStatusChange = (newValue: string, selectedItems: string[]) => {
    // if unmatched is selected, deselect other options
    if (newValue === unMatchedStatusCode) {
      return [unMatchedStatusCode];
    }

    if (selectedItems.includes(unMatchedStatusCode)) {
      return [
        ...selectedItems.filter((item) => item !== unMatchedStatusCode),
        newValue,
      ];
    }

    return [...selectedItems, newValue];
  };

  const onSelectStatus = (selectedItems: string[]) => {
    setStatusFilter(selectedItems);

    track(EventNames.providerWebClients, {
      eventAction: EventActions.click,
      eventLabel: 'status',
      featureVersion: 'v1',
      eventValue:
        selectedItems.length === statusOptions.length
          ? 'All status'
          : statusOptions
              .filter((val) => selectedItems.includes(val.key))
              .map((filtered) => filtered.label),
    });
  };

  return (
    <article className="clients-wrapper">
      <section className="clients-header-wrapper">
        <Typography weight="600" color="primary" size={24}>
          {t('clients')}
        </Typography>
      </section>
      <section className="filter-search-wrapper">
        <section className="search-bar">
          <TextBox
            variant="box-border"
            placeholder={t('searchClientName')}
            icon={searchIcon}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            animate={false}
          />
        </section>
        <section className="filter-wrapper">
          <MultiSelect
            placeholder={t('ORGANISATION')}
            options={organisationOptions || []}
            onClose={(selectedItems) => {
              setOrgnisationFilter(selectedItems);
              track(EventNames.providerWebClients, {
                eventAction: EventActions.click,
                eventCategory: EventCategories.organisation,
                eventLabel: 'organisation',
                featureVersion: 'v1',
                eventValue: selectedItems.length
                  ? selectedItems
                  : 'All organisation',
              });
            }}
            width="165px"
            enableSelectboxSearch
            selectboxSearchPlacholder="SEARCH_ORG_NAME_PLACEHOLDER"
            dropDownWidth="305px"
          />
          <MultiSelect
            placeholder={t('status')}
            options={statusOptions}
            onClose={onSelectStatus}
            updateOnSelect={onStatusChange}
            minWidth="125px"
            width="138px"
            dropDownWidth="138px"
          />
        </section>
      </section>
      <section className="client-data-wrapper">
        <Table
          isLoading={clientsListLoading}
          data={data?.conversations || []}
          columns={clientsColDefinition}
          onTableRowClick={onTableRowClick}
          onSortClick={onSortClick}
          noDataComponent={
            <NoResultsFound
              captionCopy={
                data?.conversations.length
                  ? t('NO_RESULTS_FOUND')
                  : t('NO_CLIENTS')
              }
              imageSrc={noSearchResults}
              description={
                data?.conversations.length
                  ? t('NO_RESULTS_ERR')
                  : t('NO_CLIENTS_MSG')
              }
              width="50%"
            />
          }
          height="calc(100vh - 19rem)"
          manualSorting
        />
      </section>
      {data?.conversations.length ? (
        <Pagination
          siblingCount={1}
          currentPage={data.page}
          onPageChange={onPageChange}
          pageSize={data.pageSize}
          totalCount={data.total}
          className=""
        />
      ) : null}
    </article>
  );
}

export default Clients;
