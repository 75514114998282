import { useContext, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Button } from '../../components/Button';
import Modal from '../../components/Modal';
import ClientDetailsContext from './context/ClientDetailsContext';
import { useAddClientNote, useEditClientNote } from './hooks/UseClientNotes';
import { IClientNote } from './types/client';

interface AddNotesModalProps {
  open: boolean;
  existingNote?: IClientNote;
  handleClose: () => void;
  handleSuccess: () => void;
}
function AddNotesModal({
  open,
  handleClose,
  existingNote,
  handleSuccess,
}: AddNotesModalProps) {
  const { decodedClientId, serviceType } = useContext(ClientDetailsContext);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [note, setNote] = useState(existingNote?.note ?? '');

  useEffect(() => {
    if (open) {
      setNote(existingNote?.note ?? '');
    }
  }, [open, existingNote?.note]);

  const [error, setError] = useState(false);

  const onClose = () => {
    handleClose();
  };

  const handleApiSuccess = () => {
    onClose();
    handleSuccess();
  };

  const { mutate: addNote, isLoading: isAddNotesLoading } = useAddClientNote(
    {
      note: note.toString().trim(),
      userId: decodedClientId ? +decodedClientId : undefined,
      role: serviceType,
    },
    handleApiSuccess,
    decodedClientId,
  );

  const { mutate: editNote, isLoading: isEditNotesLoading } = useEditClientNote(
    { note: note.toString().trim() },
    handleApiSuccess,
    decodedClientId,
    existingNote?.id,
  );

  useEffect(() => {
    setError(note?.length >= 5000);

    // Expanding Text Area Height
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [open, error, note, textareaRef]);

  const handleSubmit = () => {
    if (existingNote?.id) {
      editNote();
    } else {
      addNote();
    }

    handleClose();
  };

  if (!open) return null;

  return (
    <Modal
      open={open}
      handleClose={onClose}
      title={`${existingNote?.id ? 'Edit' : 'Add'} Note`}
      className="w-5/12"
    >
      <div
        className={twMerge(
          'w-full max-h-80 p-2 border-2 rounded-md',
          error ? 'border-error-300' : 'border-blueLight-300',
          error
            ? 'shadow-[0px_0px_0px_4px_#FECDCA]'
            : 'shadow-[0px_0px_0px_4px_#B2DDFF]',
        )}
      >
        <textarea
          ref={textareaRef}
          rows={4}
          className="w-full resize-none focus:border-none focus:outline-none max-h-60"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          maxLength={5000}
        />
        <div className="mt-1 flex justify-between items-center">
          <div
            className={twMerge(
              'text-gray-600 mt-0.5 leading-5 text-xs',
              error ? 'text-error-400' : 'text-gray-400',
            )}
          >
            {note?.length}/5000 characters
          </div>
          <Button
            className="h-8"
            disabled={
              error ||
              !note.toString().trim() ||
              isAddNotesLoading ||
              isEditNotesLoading
            }
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default AddNotesModal;
