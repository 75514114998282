import { RiskLevelsEnum } from './Clients';

export const variants = {
  primary: 'bg-primary-25 border-primary-100 text-gray-700',
  warning: 'bg-warning-100 text-warning-700',
  error: 'bg-error-100 text-error-700',
  errorOutline: 'border border-error-600 text-error-600',
  success: 'bg-success-100 text-success-700',
  purple: 'bg-purple-100 text-purple-700',
  gray: 'bg-gray-100 text-gray-700',
  grayOutline: 'border border-gray-500 text-gray-500',
};

export const statusBaseTooltipIconColors: Record<string, string> = {
  '0': '#344054',
  '1': '#067647',
  '2': '#4A0FC4',
  '3': '#B54708',
  '4': '#B42318',
};

export const infoTileVariants = {
  primary: 'bg-primary-50 border-primary-100 font-semibold',
  error: 'bg-error-50 border-error-50',
  gray: 'bg-gray-50 border-gray-100',
};

export const riskColorVariants: Record<
  string,
  {
    backgroundColor: string;
    hoverColor: string;
    textColor: string;
    iconColor: string;
    borderColor: string;
    iconBg: string;
    label: string;
    value: RiskLevelsEnum;
    description: string;
  }
> = {
  [RiskLevelsEnum.NO_RISK]: {
    backgroundColor: 'bg-cyan-25',
    hoverColor: 'hover:bg-cyan-25',
    textColor: 'text-cyan-900',
    iconColor: '#164C63',
    borderColor: 'border-cyan-500',
    iconBg: 'bg-cyan-100',
    label: 'No risk',
    value: RiskLevelsEnum.NO_RISK,
    description:
      'Client is doing well with no major concerns, regular check-ins are still important to maintain their well-being',
  },
  [RiskLevelsEnum.LOW_RISK]: {
    backgroundColor: 'bg-greenLight-25',
    hoverColor: 'hover:bg-greenLight-25',
    textColor: 'text-greenLight-800',
    iconColor: '#3B7C0F',
    borderColor: 'border-greenLight-400',
    iconBg: 'bg-moss-100',
    label: 'Low risk',
    value: RiskLevelsEnum.LOW_RISK,
    description:
      'Client is facing a manageable crisis, with no immediate danger or suicidal intent, and can cope with their current support and skills',
  },
  [RiskLevelsEnum.MED_RISK]: {
    backgroundColor: 'bg-warning-25',
    hoverColor: 'hover:bg-warning-25',
    textColor: 'text-warning-800',
    iconColor: '#B54708',
    borderColor: 'border-warning-400',
    iconBg: 'bg-warning-100',
    label: 'Medium risk',
    value: RiskLevelsEnum.MED_RISK,
    description:
      'Client is facing a serious crisis, with potential risk of harm. Suicidal thoughts may be present without specific plans',
  },
  [RiskLevelsEnum.HIGH_NON_CRITICAL_RISK]: {
    backgroundColor: 'bg-error-25',
    hoverColor: 'hover:bg-error-25',
    textColor: 'text-error-800',
    iconColor: '#B42318',
    borderColor: 'border-error-400',
    iconBg: 'bg-error-100',
    label: 'High risk/ Non Critical',
    value: RiskLevelsEnum.HIGH_NON_CRITICAL_RISK,
    description:
      'Client is in a severe crisis. While not in immediate danger, they may have plans or intent to harm themselves or others',
  },
  [RiskLevelsEnum.HIGH_CRITICAL_RISK]: {
    backgroundColor: 'bg-rose-25',
    hoverColor: 'hover:bg-rose-25',
    textColor: 'text-rose-800',
    iconColor: '#C01048',
    borderColor: 'border-rose-400',
    iconBg: 'bg-rose-100',
    label: 'High risk/ Critical',
    value: RiskLevelsEnum.HIGH_CRITICAL_RISK,
    description:
      'Client is in a severe crisis. They may be in immediate danger, and may have plans or intent to harm themselves or others',
  },
  [RiskLevelsEnum.RISK_NOT_ASSESED]: {
    backgroundColor: 'bg-purple-25',
    hoverColor: 'hover:bg-purple-25',
    textColor: 'text-purple-800',
    iconColor: '#5925DC',
    borderColor: 'border-purple-500',
    iconBg: 'bg-purple-100',
    label: 'Risk not assesed',
    value: RiskLevelsEnum.RISK_NOT_ASSESED,
    description: 'Client’s risk level was not assessed',
  },
};
