import { getClientCompanies } from '../../../../../shared/service/API/clients';
import { useFetch } from '../../../../../utilities/hooks/useFetch';

export const useClientCompanyOptions = () =>
  useFetch<
    {
      label: string;
      key: string;
    }[]
  >(['client-list', 'companies'], () => getClientCompanies());
