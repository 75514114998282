import moment from 'moment';
import { useContext } from 'react';
import useAuth from '../../../../utilities/hooks/useAuth';
import { PresentingIssueAuthor } from '../../../shared/constant/Clients';
import { ClientProfileDateFormat } from '../../../utilities/common/Date';
import BasicCardTemplate from '../../components/BasicCardTemplate';
import InfoTile from '../../components/InfoTile';
import UnOrderedList from '../../components/UnOrderedList';
import ClientDetailsContext from './context/ClientDetailsContext';

export default function UserPresentingIssuesCard() {
  const { clientDetailData } = useContext(ClientDetailsContext);

  const { user } = useAuth();

  const clientName = clientDetailData?.profile?.name;
  const presentingIssue = clientDetailData?.presentingIssue;
  const addedBy = presentingIssue?.addedBy;
  let authorName = '';

  if (addedBy !== undefined) {
    if (addedBy === 0) {
      authorName = `${user?.name ?? ''} (You)`;
    } else if (addedBy === 1) {
      authorName = `client ${clientDetailData?.profile?.name}`;
    } else {
      authorName = PresentingIssueAuthor?.[addedBy];
    }
  }

  const PISubContentItems = [];

  if (authorName) {
    PISubContentItems.push({
      label: (
        <>
          Added By <span className="text-gray-900">{authorName}</span>
        </>
      ),
    });
  }

  if (presentingIssue) {
    PISubContentItems.push({
      label: moment(presentingIssue?.createdAt).format(ClientProfileDateFormat),
    });
  }

  return (
    <BasicCardTemplate heading="Presenting issue" showToolTip>
      {!presentingIssue?.sessionCompleted && (
        <InfoTile
          lineClamp={2}
          variant="error"
          color="red"
          showIcon
          content={`Book a session and record ${clientName}’s presenting issues for a smooth payment process`}
        />
      )}

      {presentingIssue?.sessionCompleted && presentingIssue?.pending ? (
        <InfoTile
          lineClamp={2}
          variant="error"
          color="red"
          showIcon
          content={`Please record ${clientName}'s presenting issues for a smooth payment process`}
        />
      ) : null}

      {presentingIssue?.issue ? (
        <InfoTile
          variant="primary"
          content={
            <span className="text-xs font-semibold text-gray-900">
              {presentingIssue?.issue ?? '-'}
            </span>
          }
          subContent={
            <UnOrderedList
              className="text-gray-500"
              items={PISubContentItems}
            />
          }
        />
      ) : null}
    </BasicCardTemplate>
  );
}
