import { useParams } from 'react-router-dom';
import { useState } from 'react';
import ClientOverviewCardLoader from '../ClientOverviewCardLoader';
import { useSessionsList } from '../hooks/UseSessionsList';
import SessionCard from './SessionCard';
import NoSessionsFound from './NoSessionsFound';
import { Button } from '../../../components/Button';
import PastSessionsModal from './PastSessionsModal';

export function SessionsList({ clientId }: { clientId: string }) {
  const { serviceType } = useParams();

  const [showPastSessionsModal, setShowPastSessionsModal] = useState(false);

  const { data: sessionsList, isLoading: isSessionsListLoading } =
    useSessionsList(clientId, serviceType);

  if (!sessionsList || isSessionsListLoading) {
    return (
      <>
        <ClientOverviewCardLoader variant="card" />
        <ClientOverviewCardLoader variant="card" />
        <ClientOverviewCardLoader variant="card" />
        <ClientOverviewCardLoader variant="card" />
      </>
    );
  }

  console.log('sessionData', sessionsList);

  return (
    <div className="flex flex-col gap-3">
      {sessionsList.pages?.[0].total ? (
        <>
          {sessionsList.pages?.[0].data.map((session) => (
            <SessionCard data={session} />
          ))}

          <Button
            variant="secondary"
            className="w-full"
            onClick={() => setShowPastSessionsModal(true)}
          >
            View all
          </Button>
        </>
      ) : (
        <NoSessionsFound />
      )}
      <PastSessionsModal
        clientId={clientId}
        role={serviceType}
        open={showPastSessionsModal}
        handleClose={() => setShowPastSessionsModal(false)}
      />
    </div>
  );
}
