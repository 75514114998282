import moment from 'moment';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { ReactComponent as CopyIcon } from '../../../assets/copy.svg';
import { ReactComponent as QuestionMarkIcon } from '../../../assets/QuestionMark.svg';
import {
  ClientGenderMappings,
  ClientStatus,
} from '../../../shared/constant/Clients';
import { statusBaseTooltipIconColors } from '../../../shared/constant/Colors';
import {
  getClientStatus,
  getProviderRole,
} from '../../../utilities/common/Clients';
import { ClientMatchedOnDateFormat } from '../../../utilities/common/Date';
import Avatar from '../../components/Avatar';
import { Button } from '../../components/Button';
import { Tag } from '../../components/Tag';
import Tooltip from '../../components/Tooltip';
import UnOrderedList from '../../components/UnOrderedList';
import ClientOverviewCardLoader from './ClientOverviewCardLoader';
import ClientDetailsContext from './context/ClientDetailsContext';
import useLayout from '../../../../utilities/hooks/useLayout';

// todo to assign a unique id to the tooltip id

export default function UserInfoCard() {
  const { t } = useTranslation();
  const { updateChatWindowSettings } = useLayout();
  const { clientDetailData, isLoading, decodedClientId, encodedClientId } =
    useContext(ClientDetailsContext);

  const clientStatus = clientDetailData?.profile?.status;
  const clientName =
    clientDetailData?.profile?.name || encodedClientId || 'Client';

  const handleCopyIconClick = () => {
    navigator.clipboard.writeText(encodedClientId as string);
    toast.success('Copied Successfully!');
  };

  const ClientPersonalInfo = useMemo(() => {
    const { age = null, gender = null } = clientDetailData?.profile ?? {};

    const items = [
      {
        label: clientDetailData?.profile?.createdAt
          ? `Matched on ${moment(clientDetailData?.profile?.createdAt).format(
              ClientMatchedOnDateFormat,
            )}`
          : '-',
      },
    ];

    if (age) {
      items.push({
        label: age === 'NA' ? 'Age undisclosed' : `${age} years`,
      });
    }

    if (gender && ClientGenderMappings?.[gender]) {
      items.push({
        label: ClientGenderMappings?.[gender],
      });
    }

    return items;
  }, [clientDetailData?.profile]);

  const ClientOrgDetails = useMemo(
    () => [
      {
        label: getProviderRole(clientDetailData?.profile?.providerRole ?? '')
          ?.label,
      },
      {
        label: clientDetailData?.profile?.isB2CUser
          ? 'Private client'
          : clientDetailData?.profile?.companyName ?? 'No organisation',
      },
      {
        label: (
          <Tag
            variant="gray"
            className="flex items-center justify-center gap-1 rounded text-[10px] font-medium hover:bg-gray-200 active:bg-gray-300"
            onClick={handleCopyIconClick}
          >
            Case ID: {encodedClientId}
            <CopyIcon className="cursor-pointer w-2 h-2" />
          </Tag>
        ),
      },
    ],
    [clientDetailData?.profile, encodedClientId],
  );

  return isLoading ? (
    <ClientOverviewCardLoader variant="avatar" />
  ) : (
    <section className="w-full bg-white box-border flex flex-col gap-4 border rounded-lg border-gray-200 border-solid">
      <Avatar clientName={clientName} />
      <section className="flex flex-col gap-2 p-4">
        <section className="flex flex-wrap items-center gap-2">
          <section className="text-xl font-semibold">{clientName}</section>
          {clientStatus && (
            <Tag
              variant={getClientStatus(clientStatus).variant}
              className="flex items-center justify-center gap-1 px-2.5 py-1.5 leading-[10px]"
            >
              {t(getClientStatus(clientStatus).label)}

              <QuestionMarkIcon
                stroke={statusBaseTooltipIconColors[clientStatus]}
                data-tooltip-id={`beacon-${clientStatus}`}
              />
              <Tooltip
                id={`beacon-${clientStatus}`}
                content={t(getClientStatus(clientStatus).label)}
              />
            </Tag>
          )}
        </section>
        <UnOrderedList className="flex flex-wrap" items={ClientPersonalInfo} />
        <section>
          <UnOrderedList
            className="text-gray-400 font-normal text-[10px] flex flex-wrap"
            items={ClientOrgDetails}
          />
        </section>
        {clientStatus !== ClientStatus.UNMATCHED_CLIENTS && (
          <section className="flex flex-col gap-3">
            <Button className="w-full">{t('BOOK_SESSION')}</Button>
            <Button
              className="w-full"
              variant="secondary"
              onClick={() =>
                updateChatWindowSettings({
                  show: true,
                  initialUser: decodedClientId ? +decodedClientId : null,
                })
              }
            >
              {t('VIEW_MESSAGES')}
            </Button>
          </section>
        )}
      </section>
    </section>
  );
}
