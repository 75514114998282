export function generateUniqueHexCode(inputText: string): string {
  // Initialize a hash code with an arbitrary value
  let hashCode = 0;

  // Iterate over each character in the input text
  for (let i = 0; i < inputText.length; i += 1) {
    const charCode = inputText.charCodeAt(i);

    // Update the hash code using a simple multiplication and addition formula
    hashCode = (hashCode * 31 + charCode) % 0x7fffff;
  }

  // Convert the hash code to a hexadecimal string with leading zeros
  const hexCode = '#'.concat(hashCode.toString(16).padStart(6, '0'));

  return hexCode;
}

export function extractInitials(fullName: string): string {
  const names = fullName.split(' ');
  if (names.length < 2) {
    return fullName.charAt(0).toUpperCase();
  }
  return names.map((name) => name.charAt(0).toUpperCase()).join('');
}
