import { PropsWithChildren } from 'react';
import { Tab } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

interface TabPanelProps {
  className?: string;

  /** This key *must* match one of the keys in the
   * tabs list you've defined above.
   */
  key: number;
}

export function TabPanel({
  key,
  className,
  children,
}: PropsWithChildren<TabPanelProps>) {
  return (
    <Tab.Panel key={key} className={twMerge('bg-gray-25 p-3', className)}>
      {children}
    </Tab.Panel>
  );
}

interface TabProps {
  list: { key: number; label: string }[];
  // className for the Tab container, not the tabs themselves.
  className?: string;

  selectedIndex?: number;
  setSelectedIndex?: (newIndex: number) => void;
}

export function Tabs({
  list,
  children,
  className,
  selectedIndex,
  setSelectedIndex,
}: PropsWithChildren<TabProps>) {
  return (
    <Tab.Group
      as="div"
      className={twMerge('w-full', className)}
      selectedIndex={selectedIndex}
      onChange={setSelectedIndex}
    >
      <Tab.List className="flex p-1">
        {list.map((tabItem) => (
          <Tab
            key={tabItem.key}
            className={({ selected }) =>
              twMerge(
                'w-full bg-gray-25 py-2 text-sm font-medium leading-5 border-b transition-colors',
                selected
                  ? 'text-primary-600 font-semibold border-b-2 border-primary-600'
                  : 'text-gray-500 hover:bg-gray-100 border-b border-gray-200',
              )
            }
          >
            {tabItem.label}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="mt-2">{children}</Tab.Panels>
    </Tab.Group>
  );
}
