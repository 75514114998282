import moment from 'moment';
import { useContext, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import BasicCardTemplate from '../../components/BasicCardTemplate';
import InfoTile from '../../components/InfoTile';
import { Button } from '../../components/Button';
import { ClientProfileDateFormat } from '../../../utilities/common/Date';
import AddNotesModal from './AddNotesModal';
import { useClientNotes } from './hooks/UseClientNotes';
import ClientDetailsContext from './context/ClientDetailsContext';
import ClientOverviewCardLoader from './ClientOverviewCardLoader';

export default function UserNotesCard() {
  // const { t } = useTranslation();
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
  const { decodedClientId, serviceType, isLoading } =
    useContext(ClientDetailsContext);

  const {
    data: clientNotes,
    isLoading: isNotesLoading,
    refetch: refetchNotes,
  } = useClientNotes(decodedClientId, serviceType);

  // picking recent note
  const existingNote = clientNotes?.[0];

  return isLoading || isNotesLoading ? (
    <ClientOverviewCardLoader variant="card" />
  ) : (
    <>
      <BasicCardTemplate heading="Provider notes" showToolTip={false}>
        {!existingNote ? (
          <InfoTile
            lineClamp={2}
            variant="gray"
            content="No notes yet! Start adding notes to capture valuable insights and enhance your sessions"
          />
        ) : (
          <InfoTile
            lineClamp={2}
            variant="gray"
            className="text-justify"
            key={existingNote?.updatedAt}
            content={existingNote?.note}
            subContent={moment(existingNote?.updatedAt).format(
              ClientProfileDateFormat,
            )}
            expandable
          />
        )}

        <Button
          className="w-full"
          variant="secondary"
          onClick={() => setShowNotesModal(true)}
        >
          {existingNote ? 'Edit notes' : 'Add notes'}
        </Button>
      </BasicCardTemplate>
      <AddNotesModal
        open={showNotesModal}
        existingNote={existingNote}
        handleClose={() => setShowNotesModal(false)}
        handleSuccess={() => refetchNotes()}
      />
    </>
  );
}
