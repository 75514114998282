import moment from 'moment';
import {
  APIDateFormat,
  getSlotsByWeekDayForDailyOverrides,
  transformSlotsToWeekDay,
} from '../../../utilities/common/Date';
import { beautifyUrl } from '../../../utilities/common/Path';
import Config from '../../Config';
import NetworkClient from '../../service/Network';
import {
  DefaultSlotItem,
  IProviderDailyData,
  IProviderDaySlot,
  IProviderSlot,
  InPersonClinic,
} from '../../types/response/provider';

const errors = {
  generic: 'UNABLE_TO_LOAD',
};

const client = new NetworkClient();

export const getDailyData = (date: string) => async () => {
  const response = await client.doGet(
    beautifyUrl(Config.api.slots.getDailyData, [date]),
  );
  if (response && response.data) {
    const data = response.data as IProviderDailyData;
    const slots = {
      overriddenSlots: {},
      defaultSlots: {},
    };
    if (data.override) {
      const overrideSlots = getSlotsByWeekDayForDailyOverrides(data.overrides);
      slots.overriddenSlots = overrideSlots;
    }

    if (data.defaultSlots.length) {
      const defaultMeetingSlots = getSlotsByWeekDayForDailyOverrides(
        data.defaultSlots,
      );
      slots.defaultSlots = defaultMeetingSlots;
    }

    return {
      ...response.data,
      slots,
    };
  }

  throw new Error(errors.generic);
};

export const getDailyDataV2 = (date: string) => async () => {
  const response = await client.doGet(
    beautifyUrl(Config.api.slots.getDailyDataV2, [date]),
  );
  if (response && response.data) {
    const data = response.data as IProviderDailyData;
    const slots = {
      overriddenSlots: {},
      defaultSlots: {},
    };
    if (data.override) {
      const overrideSlots = getSlotsByWeekDayForDailyOverrides(data.overrides);
      slots.overriddenSlots = overrideSlots;
    }

    if (data.defaultSlots.length) {
      const defaultMeetingSlots = getSlotsByWeekDayForDailyOverrides(
        data.defaultSlots,
      );
      slots.defaultSlots = defaultMeetingSlots;
    }

    return {
      ...response.data,
      slots,
    };
  }

  throw new Error(errors.generic);
};

export const updateDailyOverride =
  (
    localDate: string,
    slotsMap: Record<string, IProviderDaySlot>,
    clinicsList: InPersonClinic[],
    defaultSlots: DefaultSlotItem[],
    overriddenSlots: IProviderSlot,
  ) =>
  async () => {
    const slots = transformSlotsToWeekDay(slotsMap, clinicsList, false);

    const currentDaySlots = slots.filter(
      (slot) => slot.dayOfWeek === moment(localDate, APIDateFormat).day(),
    );

    const existingOverriddenLocations = [
      ...Object.values(overriddenSlots)
        .flatMap((ovSlot) => ovSlot.slotsRange)
        .flatMap((ovRange) => ovRange.locationId),
      ...defaultSlots.map((dSlot) => dSlot.locationId),
    ];
    const body = currentDaySlots.flatMap((cSlot) => {
      if (
        (cSlot.slots || '') === '' &&
        !existingOverriddenLocations.includes(cSlot.locationId)
      ) {
        return [];
      }
      return {
        localDate,
        slots: cSlot.slots || '',
        locationId: cSlot.locationId,
      };
    });

    const response = await client.doPost(
      Config.api.slots.updateDailyOverrideV2,
      body,
    );

    return response;
  };

export const getMonthlyDailyOverrides = async (month: string) => {
  let overriddenDates = [];
  const url = new URL(Config.api.slots.getMonthlyOverrides);
  url.searchParams.append('date', month);
  const response = await client.doGet(url.toString());
  if (response && response.data) {
    const { data } = response;
    if (!data || !data.length) {
      return [];
    }

    overriddenDates = data.reduce((acc: [string, string][], current: any) => {
      const [date, isOverridden] = Object.entries(current)[0];
      if (isOverridden) {
        acc.push([date, current.variant]);
      }

      return acc;
    }, []);

    return overriddenDates;
  }

  return [];
};
