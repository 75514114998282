import { createContext } from 'react';
import { IClientDetail } from '../types/client';

interface IClientDetailsContext {
  clientDetailData?: IClientDetail;
  isLoading?: boolean;
  decodedClientId?: string;
  encodedClientId?: string;
  serviceType?: string;
}

const ClientDetailsContext = createContext<IClientDetailsContext>(
  {} as IClientDetailsContext,
);

export default ClientDetailsContext;
