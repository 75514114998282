import Config from '../../../../shared/Config';
import NetworkClient from '../../../../shared/service/Network';
import { beautifyUrl } from '../../../../utilities/common/Path';
import {
  IAddNotesPayloadType,
  IAddRiskLevelPayloadType,
} from '../../../view/app-components/ClientDetails/types/client';

const client = new NetworkClient();

const errorMessages = {
  generic: 'Unable to fetch client details, please try again!',
};

export const getClientDetail = async (clientId: string, role?: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.apiV2.client.detail, [clientId]),
    {},
    {
      role,
    },
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const getClientNotes = async (clientId: string, role?: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.apiV2.client.notes, [clientId]),
    {},
    {
      role,
    },
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const addClientNote = async (
  clientId: string,
  payload: IAddNotesPayloadType,
) => {
  const response = await client.doPost(
    beautifyUrl(Config.apiV2.client.notes, [clientId]),
    payload,
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const editClientNote = async (
  clientId: string,
  noteId: string,
  payload: Pick<IAddNotesPayloadType, 'note'>,
) => {
  const response = await client.doPatch(
    beautifyUrl(Config.apiV2.client.editNotes, [clientId, noteId]),
    payload,
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const getClientRiskLevels = async (clientId: string, role?: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.apiV2.client.riskLevels, [clientId]),
    {},
    {
      role,
    },
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const addClientRiskLevel = async (
  clientId: string,
  payload: IAddRiskLevelPayloadType,
) => {
  const response = await client.doPost(
    beautifyUrl(Config.apiV2.client.riskLevels, [clientId]),
    payload,
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const editClientRiskLevel = async (
  clientId: string,
  riskId: string,
  payload: Pick<IAddRiskLevelPayloadType, 'risk'>,
) => {
  const response = await client.doPatch(
    beautifyUrl(Config.apiV2.client.editRiskLevels, [clientId, riskId]),
    payload,
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const getClientEmergencyContacts = async (clientId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.apiV2.client.emergencyContacts, [clientId]),
    {},
  );
  if (response && response.data) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};
