import { toast } from 'sonner';
import { useFetch, useMutation } from '../../../../../utilities/hooks/useFetch';
import {
  addClientNote,
  editClientNote,
  getClientNotes,
} from '../../../../shared/service/API/client';
import { IAddNotesPayloadType, IClientNote } from '../types/client';

export function useClientNotes(clientId?: string, role?: string) {
  return useFetch<IClientNote[]>(
    ['client-details-v2-notes', clientId, role],
    () => (clientId ? getClientNotes(clientId, role) : undefined),
    {
      enabled: Boolean(clientId),
      refetchOnWindowFocus: false,
    },
  );
}

export function useAddClientNote(
  payload: IAddNotesPayloadType,
  handleSuccess: () => void,
  clientId?: string,
) {
  return useMutation(
    () => (clientId ? addClientNote(clientId, payload) : undefined),
    {
      onSuccess: () => {
        toast.success('Notes added successfully.');
        handleSuccess();
      },
      onError: () => {
        toast.error(
          'Something went wrong while adding notes, please try after sometime.',
        );
      },
    },
  );
}

export function useEditClientNote(
  payload: Pick<IAddNotesPayloadType, 'note'>,
  handleSuccess: () => void,
  clientId?: string,
  noteId?: string,
) {
  return useMutation(
    () =>
      clientId && noteId
        ? editClientNote(clientId, noteId, payload)
        : undefined,
    {
      onSuccess: () => {
        toast.success('Notes updated successfully.');
        handleSuccess();
      },
      onError: () => {
        toast.error(
          'Something went wrong while updating notes, please try after sometime.',
        );
      },
    },
  );
}
