import { twMerge } from 'tailwind-merge';

export interface UnorderedListProps {
  items: {
    label: string | JSX.Element;
    startIcon?: JSX.Element;
    endIcon?: JSX.Element;
  }[];
  className?: string;
}
export default function UnOrderedList({
  items,
  className,
}: UnorderedListProps) {
  return (
    <ul
      className={twMerge(
        'text-xs text-gray-600 font-medium list-disc inline-flex w-full',
        className,
      )}
    >
      {items.map(({ label, startIcon, endIcon }, idx: number) => (
        <li className="list-none [&:nth-child(2)]:max-w-[35%] marker:mb-1 ">
          <section className="flex items-center">
            {idx !== 0 && <span className="text-gray-500">・</span>}
            {startIcon}
            <section className="truncate">{label}</section>
            {endIcon}
          </section>
        </li>
      ))}
    </ul>
  );
}
