import { useEffect, useRef } from 'react';
import { useInView } from 'framer-motion';
import Modal from '../../../components/Modal';
import { useSessionsList } from '../hooks/UseSessionsList';
import SessionCard from './SessionCard';
import ClientOverviewCardLoader from '../ClientOverviewCardLoader';
import { SessionListPageSize } from '../constants';
import NoSessionsFound from './NoSessionsFound';

interface PastSessionsProps {
  open: boolean;
  handleClose: () => void;
  clientId: string;
  role?: string;
}
export default function PastSessionsModal({
  clientId,
  role,
  open,
  handleClose,
}: PastSessionsProps) {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const { data, fetchNextPage, isFetchingNextPage } = useSessionsList(
    clientId,
    role,
    2,
  );

  useEffect(() => {
    fetchNextPage();
  }, [isInView]);

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title="Past Sessions"
      subtitle="Last session on 14 Feb 2024, 3:15 PM"
      className="w-6/12"
    >
      <section className="mt-8 flex flex-col gap-4 overflow-scroll">
        {(data?.pages.length || 0) > 1 ? (
          data?.pages.map((page) =>
            page.data.map((session) => <SessionCard data={session} />),
          )
        ) : (
          <NoSessionsFound />
        )}

        {(data?.pages?.[data.pages.length - 1].count || 0) >=
        SessionListPageSize ? (
          <div ref={ref}>
            {isFetchingNextPage ? (
              <ClientOverviewCardLoader variant="card" />
            ) : null}
          </div>
        ) : null}
      </section>
    </Modal>
  );
}
